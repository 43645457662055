import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appAPI } from '../../api';
import { login } from '../../redux/actions/login';
import Cookies from 'universal-cookie';
import './Logining.css';
//в теле запроса: {login: "demo@cloveri.com", email: "demo@cloveri.com", password: "jAamqBf2uPoS"}
const Logining = () => {
    const cookies = new Cookies();

    const [emailValue,setEmailValue] = useState('')
    const [isEmail, setIsEmail] = useState(false)
    const [emailPlaceholder, setEmailPlaceholder] = useState('Email')

    const [passwordValue,setPasswordValue] = useState('')
    const [isPassword, setIsPassword] = useState(false)
    const [passwordPlaceholder, setPasswordPlaceholder] = useState('Пароль')

    const [isDisabled, setIsDisabled] = useState(false)
    const [isAuthFalt, setIsAuthFalt] = useState(false)
    const [errorMassege, setErrorMassege] = useState('')
    const [isNotValidUser,setIsNotValidUser] = useState(false)
    const [isPasswordIn, setIsPasswordIn] = useState(false)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    const setButtonActive =() =>{
        if(emailValue !== '' && passwordValue !== ''){
            setIsDisabled(true)
        }else{
            setIsDisabled(false)
        }
    }
    
    const emailChange = (e) =>{
        setEmailValue(e.target.value)
        if(e.target.value !== ''){
            setIsEmail(true)
        }
    }

    const passwordChange = (e) =>{
        setPasswordValue(e.target.value)
        if(e.target.value !== ''){
            setIsPassword(true)
            setIsPasswordIn(true)     
        }else{
            setIsPassword(false)
            setIsPasswordIn(false) 
        }
    }

    const showPassword =(e) =>{
        setIsPasswordVisible(!isPasswordVisible)   
    }

    const blurEmailHendler = () =>{
        if(emailValue !== ''){
            setIsEmail(true)
            setEmailPlaceholder('Email')
        }else{
            setIsEmail(false)
            setEmailPlaceholder('Email')
        }
    }
    
    const blurPasswordHendler = () =>{
        if(passwordValue !== ''){
            setIsPassword(true)
            setPasswordPlaceholder('Пароль')            
        }else{
            setIsPassword(false)
            setPasswordPlaceholder('Пароль')
        }
    }

    async function fetchAuthorization() {
        const response = appAPI.login(emailValue, emailValue, passwordValue)
        return response    
        .then((response) => {
            if(response.status === 200){
                // localStorage.setItem('token', response.data.token);
                localStorage.setItem('company_id', response.data.user.company_id);
                cookies.set('cl_t',response.data.token,{domain:'corp.cloveri.com'})
                cookies.set('cl_p',response.data.user.person,{domain:'corp.cloveri.com'})
                cookies.set('cl_t',response.data.token)
                cookies.set('cl_p',response.data.user.person)
                document.location.pathname='/structure'
            }else if(response.status === 400){
                setIsAuthFalt(true)
                setErrorMassege('Неверный логин или пароль')
                setIsDisabled(false)
            }else if(response.status === 403){
                setIsNotValidUser(true)
            }
        })

    }

    useEffect(() =>{
        if(isEmail === true && isPassword === true ){
            setIsDisabled(true)
        }else{
            setIsDisabled(false)
        }        
    },[isEmail,isPassword])
    
  return (
    <div className='Login_container'>
      <div className='LoginForm_section'>
        <div className="LoginForm_container">
            <div className='LoginForm_titleBlock'>
                <svg width="57" height="54" viewBox="0 0 57 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1960_12971)">
                    <path d="M0 38.4257L13.6303 24.7823V52.0691L0 38.4257Z" fill="#31BE00"/>
                    <path d="M4.08984 15.2315L13.6311 5.68115V24.7819L4.08984 15.2315Z" fill="#F0D031"/>
                    <path d="M13.6301 24.7825L49.0426 4.31748L27.597 0.563149L13.6301 24.7825Z" fill="#FF8E25"/>
                    <path d="M13.6309 24.7823L56.126 49.3404L30.3974 53.8689L13.6309 24.7823Z" fill="#2E3192"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1960_12971">
                    <rect width="56.225" height="53.9973" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
                <svg width="137" height="33" viewBox="0 0 137 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.664062 24.3671V0.434448H3.10719V11.736L14.0265 0.434448H17.151L5.99905 11.9853L18.0651 24.3671H14.8741L3.10719 12.4008V24.3671H0.664062Z" fill="#373737"/>
                    <path d="M18.7369 24.3671V22.2231C19.4017 22.345 19.9058 22.3173 20.2493 22.14C20.6039 21.9517 20.8642 21.6414 21.0304 21.2093C21.1966 20.7661 21.3351 20.2177 21.4459 19.5639C21.6232 18.4781 21.7839 17.2815 21.9279 15.974C22.0719 14.6666 22.1994 13.2262 22.3102 11.6529C22.432 10.0684 22.5539 8.32335 22.6758 6.41761H34.8748V24.3671H32.4483V8.76101H24.9361C24.8586 9.93548 24.7699 11.1044 24.6702 12.2678C24.5705 13.4312 24.4652 14.5503 24.3544 15.625C24.2547 16.6887 24.1439 17.6693 24.022 18.5667C23.9001 19.4642 23.7727 20.2398 23.6398 20.8935C23.4403 21.8796 23.1744 22.6663 22.842 23.2536C22.5207 23.8408 22.0387 24.2286 21.3961 24.4169C20.7534 24.6053 19.867 24.5887 18.7369 24.3671Z" fill="#373737"/>
                    <path d="M48.3434 24.8657C46.5595 24.8657 45.036 24.4613 43.7729 23.6524C42.5098 22.8436 41.5403 21.7245 40.8645 20.2952C40.1886 18.8659 39.8506 17.2261 39.8506 15.3757C39.8506 13.4921 40.1941 11.8412 40.8811 10.423C41.568 9.00477 42.5431 7.90232 43.8062 7.11564C45.0804 6.31789 46.5928 5.91901 48.3434 5.91901C50.1384 5.91901 51.6674 6.32343 52.9305 7.13226C54.2047 7.93002 55.1742 9.04355 55.839 10.4729C56.5148 11.8911 56.8528 13.5254 56.8528 15.3757C56.8528 17.2593 56.5148 18.9158 55.839 20.3451C55.1631 21.7633 54.1881 22.8713 52.9139 23.6691C51.6397 24.4668 50.1162 24.8657 48.3434 24.8657ZM48.3434 22.5223C50.3378 22.5223 51.8225 21.863 52.7975 20.5445C53.7726 19.2149 54.2601 17.492 54.2601 15.3757C54.2601 13.2041 53.767 11.4756 52.7809 10.1903C51.8059 8.90505 50.3267 8.26242 48.3434 8.26242C47.0027 8.26242 45.8947 8.56711 45.0194 9.17651C44.1552 9.77483 43.507 10.6114 43.0749 11.6861C42.6539 12.7498 42.4433 13.9797 42.4433 15.3757C42.4433 17.5363 42.9419 19.2703 43.9391 20.5778C44.9363 21.8741 46.4044 22.5223 48.3434 22.5223Z" fill="#373737"/>
                    <path d="M61.832 24.3671V6.41761H69.2279C69.6046 6.41761 70.0588 6.43977 70.5907 6.48409C71.1336 6.52841 71.6377 6.61151 72.1031 6.73338C73.1114 6.9993 73.9202 7.54222 74.5296 8.36213C75.1501 9.18205 75.4603 10.1682 75.4603 11.3205C75.4603 11.9631 75.3606 12.5393 75.1611 13.0489C74.9728 13.5475 74.7013 13.9797 74.3468 14.3453C74.1806 14.5337 73.9978 14.6999 73.7983 14.8439C73.5989 14.9768 73.405 15.0876 73.2166 15.1763C73.5712 15.2428 73.9534 15.3979 74.3634 15.6416C75.006 16.0184 75.4936 16.528 75.826 17.1707C76.1583 17.8022 76.3245 18.5723 76.3245 19.4808C76.3245 20.7107 76.0309 21.7134 75.4437 22.489C74.8565 23.2646 74.0698 23.7965 73.0837 24.0845C72.6405 24.2064 72.1585 24.284 71.6377 24.3172C71.1281 24.3505 70.6461 24.3671 70.1918 24.3671H61.832ZM64.2917 22.2896H70.0755C70.3081 22.2896 70.6018 22.273 70.9563 22.2397C71.3109 22.1954 71.6267 22.14 71.9037 22.0735C72.5574 21.9073 73.0338 21.5749 73.333 21.0763C73.6432 20.5667 73.7983 20.0127 73.7983 19.4144C73.7983 18.6277 73.5656 17.9851 73.1003 17.4865C72.646 16.9768 72.0366 16.6665 71.2721 16.5557C71.0173 16.5003 70.7513 16.4671 70.4743 16.456C70.1973 16.4449 69.948 16.4394 69.7264 16.4394H64.2917V22.2896ZM64.2917 14.3453H69.311C69.599 14.3453 69.9203 14.3287 70.2749 14.2954C70.6405 14.2511 70.9563 14.1791 71.2222 14.0794C71.7984 13.8799 72.2194 13.5365 72.4853 13.0489C72.7623 12.5614 72.9008 12.0296 72.9008 11.4534C72.9008 10.8219 72.7513 10.2679 72.4521 9.79144C72.164 9.31501 71.7264 8.98261 71.1391 8.79425C70.7403 8.65021 70.3082 8.56711 69.8428 8.54495C69.3885 8.52279 69.1004 8.51171 68.9786 8.51171H64.2917V14.3453Z" fill="#373737"/>
                    <path d="M88.562 24.8657C86.8113 24.8657 85.2934 24.4779 84.0081 23.7023C82.7339 22.9267 81.7423 21.8353 81.0332 20.4282C80.3241 19.021 79.9695 17.3701 79.9695 15.4754C79.9695 13.5143 80.3185 11.8191 81.0165 10.3898C81.7146 8.96045 82.6952 7.858 83.9583 7.0824C85.2325 6.30681 86.7338 5.91901 88.4623 5.91901C90.235 5.91901 91.7475 6.32897 92.9995 7.14888C94.2515 7.95772 95.1989 9.12111 95.8415 10.6391C96.4841 12.157 96.7777 13.9686 96.7223 16.0738H94.2294V15.2095C94.185 12.8827 93.6809 11.1266 92.7169 9.94102C91.7641 8.75547 90.368 8.1627 88.5287 8.1627C86.6008 8.1627 85.1217 8.78871 84.0912 10.0407C83.0719 11.2928 82.5622 13.0766 82.5622 15.3923C82.5622 17.6527 83.0719 19.4088 84.0912 20.6609C85.1217 21.9018 86.5787 22.5223 88.4623 22.5223C89.7475 22.5223 90.8666 22.2287 91.8195 21.6414C92.7834 21.0431 93.5424 20.1844 94.0964 19.0653L96.3733 19.9462C95.6642 21.5085 94.6172 22.7217 93.2322 23.586C91.8583 24.4391 90.3015 24.8657 88.562 24.8657ZM81.698 16.0738V14.0295H95.4094V16.0738H81.698Z" fill="#373737"/>
                    <path d="M109.801 24.8657C108.15 24.8657 106.754 24.4502 105.613 23.6192C104.471 22.7771 103.607 21.6414 103.02 20.2121C102.433 18.7717 102.139 17.1596 102.139 15.3757C102.139 13.5586 102.433 11.941 103.02 10.5227C103.618 9.09341 104.494 7.9688 105.646 7.14888C106.798 6.32897 108.216 5.91901 109.901 5.91901C111.54 5.91901 112.948 6.33451 114.122 7.1655C115.297 7.9965 116.194 9.12665 116.814 10.556C117.446 11.9853 117.762 13.5919 117.762 15.3757C117.762 17.1707 117.446 18.7828 116.814 20.2121C116.183 21.6414 115.274 22.7771 114.089 23.6192C112.903 24.4502 111.474 24.8657 109.801 24.8657ZM101.724 32.3446V6.41761H103.917V19.9296H104.183V32.3446H101.724ZM109.552 22.5888C110.804 22.5888 111.845 22.273 112.676 21.6414C113.507 21.0099 114.128 20.1512 114.538 19.0653C114.959 17.9684 115.169 16.7386 115.169 15.3757C115.169 14.024 114.964 12.8052 114.554 11.7194C114.144 10.6335 113.518 9.77483 112.676 9.14327C111.845 8.51171 110.787 8.19594 109.502 8.19594C108.25 8.19594 107.208 8.50063 106.377 9.11003C105.557 9.71943 104.942 10.567 104.532 11.6529C104.122 12.7276 103.917 13.9686 103.917 15.3757C103.917 16.7607 104.122 18.0017 104.532 19.0986C104.942 20.1844 105.563 21.0376 106.394 21.658C107.225 22.2785 108.277 22.5888 109.552 22.5888Z" fill="#373737"/>
                    <path d="M137.001 6.41761V24.3671H134.624V10.3898L124.785 24.3671H122.724V6.41761H125.101V20.2786L134.957 6.41761H137.001Z" fill="#373737"/>
                </svg>
            </div>
            {isNotValidUser ? 
            <div className="LoginForm_Block">
            <p className='Login_errorTitle'>Доступ запрещен</p>
            <p className='Login_errorText'>
                У вас нет доступа к личному кабинету.
                Свяжитесь
                <a className='Login_errorLink' href='https://t.me/cloveri_com' target='_blank'>с администратором</a><br/>
                для получения прав доступа
            </p>
            <button className='Login_errorButton' onClick={() => setIsNotValidUser(false)}>На страницу авторизации</button>
        </div> 
        :
            <div className="LoginForm_Block">
                <input onClick={(e) => setButtonActive(e)} onFocus={() => setEmailPlaceholder('')} onBlur={blurEmailHendler} onChange={(e) => emailChange(e)} className={isAuthFalt ? 'LoginForm_emailError' : 'LoginForm_email'} type='email' placeholder={emailPlaceholder}></input>
                <label className='Login_passwordLabel'>
                    {isPasswordVisible ? 
                    <input onClick={(e) =>setButtonActive(e)} onFocus={() => setPasswordPlaceholder('')} onBlur={blurPasswordHendler} onChange={(e) => passwordChange(e)} className={isAuthFalt ? 'LoginForm_passwordError' : 'LoginForm_password'} type='text' placeholder={passwordPlaceholder}></input>
                    : 
                    <input onClick={(e) =>setButtonActive(e)} onFocus={() => setPasswordPlaceholder('')} onBlur={blurPasswordHendler} onChange={(e) => passwordChange(e)} className={isAuthFalt ? 'LoginForm_passwordError' : 'LoginForm_password'} type='password' placeholder={passwordPlaceholder}></input>}
                    
                    {isPasswordIn ? 
                    <>
                        {isPasswordVisible ? <button className='Login_passwordBtn' onClick={(e) =>showPassword(e)}>                        
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1960_12773)">
                                <path d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M1 1L23 23" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_1960_12773">
                                <rect width="24" height="24" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </button> : <button className='Login_passwordBtn' onClick={() =>showPassword(true)}>                        
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>}
                    </> : null}
                    
                </label>
                {errorMassege ?<span className='LoginForm_error'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#CF4317" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 8V12" stroke="#CF4317" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 16H12.01" stroke="#CF4317" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>{errorMassege}
                    </span> : null}
                {isDisabled ? <button onClick={() => fetchAuthorization()} className='LoginForm_button'>Войти</button> : <button className='LoginForm_buttonDisable' disabled>Войти</button>}                
            </div>            
            }
            {isNotValidUser ? null : <a className='LoginForm_Link' href='https://p.cloveri.com/recovery_password/' target='_blank'>Не помню пароль</a>}
        </div>
      </div>
      <div className='LoginImg_section'>
        <svg width="645" height="794" viewBox="0 0 645 794" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.2" clipPath="url(#clip0_1960_12957)">
            <path d="M968.306 81.6071L532.796 0.96228L262.252 471.665L968.306 81.6071Z" fill="#FF8E25"/>
            <path d="M262.252 79.4137V471.666L79.2012 272.068L262.252 79.4137Z" fill="#F0D031"/>
            <path d="M0.75 747.093L262.251 471.666V1020.82L0.75 747.093Z" fill="#2BA52F"/>
            <path d="M604.05 1046.97L262.252 471.666L1125.21 953.677L604.05 1046.97Z" fill="#2E3192"/>
            </g>
            <defs>
            <clipPath id="clip0_1960_12957">
            <rect width="768" height="856.386" fill="white"/>
            </clipPath>
            </defs>
        </svg>
      </div>
    </div>
  );
};

export default Logining;
