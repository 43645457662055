import { FAIL_LOGIN, SET_LOGIN } from '../../constants';

const initialState = {
    personalData: {}
  };

const login = (state = initialState, action) => {
    switch (action.type) {
      case SET_LOGIN:
        return {
          ...state,
          personalData: action.payload,
        };
        case FAIL_LOGIN:
          return{
            ...state,
            personalData: action.payload
          };
  
      default:
        return state;
    }
  };
  
  export default login;