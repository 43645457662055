import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { LIMIT, PROJECT_ID, ORGSTRUCTURE, ITEM } from '../../constants';
import { findStaff } from '../../redux/actions/staff';
import style from './OrgStructureTreeElem.module.css';
import PopupEdit from '../popup/PopupEdit/PopupEdit';
import AddNewElementPopup from '../popup/AddNewElementPopup/AddNewElementPopup';
import { setActiveStaffBtn } from '../../redux/actions/staff';

const OrgStructureTreeElem = ({
  elem,
  handleDragEnd,
  dragItem,
  dragOverItem,
  setItemId
}) => {
  const dispatch = useDispatch();
  const { isActiveStaff } = useSelector(({ staff }) => staff);
  const [isActive, setIsActive] = useState(false);

  const handleTreeBranch = () => {
    setIsActive(!isActive);
  };

  // Drag`n`Drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.currentTarget.style.borderTop = 'solid 2px #2E3192';
  };

  const handleDragLeave = (e) => {
    e.currentTarget.style.borderTop = 'none';
  };

  const handleDrop = (e) => {
    e.currentTarget.style.borderTop = 'none';
  };

  const fetchStaff = (e) => {
    const staffData = {
      node_id: Number(e.currentTarget.id),
      project_id: PROJECT_ID,
      item_type: ORGSTRUCTURE,
      item: ITEM,
      page: 1,
      limit: LIMIT,
      is_only_node: false,
    };
    setItemId(elem.id)
    dispatch(setActiveStaffBtn(elem.id));
    dispatch(findStaff(staffData));
  };

  return (
    <div
      className={cn([style.branch], {
        [style.branchLvl2]: elem.level_node === 2,
        [style.branchLvl3]: elem.level_node === 3,
        [style.branchLvl4]: elem.level_node === 4,
      })}>
      {/* ARROW */}
      <div
        onClick={handleTreeBranch}
        className={cn([style.branchArrow], {
          [style.active]: isActive,
        })}>
        <svg
          width='8'
          height='14'
          viewBox='0 0 8 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M1 13L7 7L1 1'
            stroke='#D9D9D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>

      {/* PROJECT NAME */}
      <div
        id={elem.id}
        draggable
        onDragStart={() => (dragItem.current = elem.id)}
        onDragEnter={() => (dragOverItem.current = elem.id)}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragEnd={handleDragEnd}
        className={style.branchName}>
        {elem.attributes && <p>{JSON.parse(elem.attributes).name}</p>}
      </div>

      <div className={style.treeMenu}>
        {/* STAFF */}
        <div
          onClick={fetchStaff}
          id={elem.id}
          className={cn([style.branchStaff], {
            [style.branchStaffChosen]: isActiveStaff === elem.id,
          })}>
          <svg
            width='32'
            height='26'
            viewBox='0 0 32 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M22.3333 25V22.3333C22.3333 20.9188 21.7714 19.5623 20.7712 18.5621C19.771 17.5619 18.4145 17 17 17H6.33333C4.91885 17 3.56229 17.5619 2.5621 18.5621C1.5619 19.5623 1 20.9188 1 22.3333V25'
              stroke='#737373'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M11.6663 11.6667C14.6119 11.6667 16.9997 9.27885 16.9997 6.33333C16.9997 3.38781 14.6119 1 11.6663 1C8.72082 1 6.33301 3.38781 6.33301 6.33333C6.33301 9.27885 8.72082 11.6667 11.6663 11.6667Z'
              stroke='#737373'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M30.333 25.0005V22.3338C30.3321 21.1521 29.9388 20.0042 29.2148 19.0702C28.4908 18.1363 27.4772 17.4693 26.333 17.1738'
              stroke='#737373'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M21 1.17383C22.1472 1.46756 23.164 2.13476 23.8902 3.07024C24.6163 4.00572 25.0105 5.15627 25.0105 6.34049C25.0105 7.52472 24.6163 8.67527 23.8902 9.61075C23.164 10.5462 22.1472 11.2134 21 11.5072'
              stroke='#737373'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          {/* COUNT OF STAFF */}
          {/* <span className={style.branchStaffCount}>4221</span> */}
        </div>

        {/* EDIT MENU */}
        <div className={style.branchEditMenu}>
          <PopupEdit parentId={elem.id} elem={elem} />
        </div>

        {/* PLUS */}
        {elem.level_node >= 4 ? null : <div className={style.branchPlus}>
          <AddNewElementPopup parentId={elem.id} />
        </div>}        
      </div>
    </div>
  );
};

export default OrgStructureTreeElem;
