import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import style from './MoreButton.module.css';
import { findStaff } from '../../../redux/actions/staff';
import { PROJECT_ID, ORGSTRUCTURE, ITEM, LIMIT } from '../../../constants';

const MoreButton = ({ staffList }) => {
  const dispatch = useDispatch();
  const { page, nodeId } = useSelector(({ staff }) => staff);

  const loadMore = (e) => {
    const staffData = {
      node_id: nodeId,
      project_id: PROJECT_ID,
      item_type: ORGSTRUCTURE,
      item: ITEM,
      page: page,
      limit: LIMIT + 5,
      is_only_node: false,
    };

    if (page === staffList.length) {
      return
    } else {
      dispatch(findStaff(staffData));
    }
  };

  if (staffList <= 0) {
    return;
  }

  return (
    <div onClick={loadMore} className={style.staffMore}>
      Загрузить еще
    </div>
  );
};

export default MoreButton;
