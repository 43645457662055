import axios from 'axios';
import { useDispatch } from 'react-redux';

import {
  BASE_URL,
  GET_QUERY,
  NODE,
  ATTRIBUTES,
  EMPLOYEES_SEARCH,
  PROJECT_ID,
  ITEM,
  ORGSTRUCTURE,
  HIDDEN,
  ORDER,
} from '../constants';
import { failLogin } from '../redux/actions/login';

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
});
// const dispatch = useDispatch();
export const appAPI = {
  async getTree() {       
    try {
      return await instance.get(GET_QUERY);
    } catch (err) {
    }
  },

  // ON ADD QUERY
  async addElement(parentId, elemData) {
    try {
      if (parentId) {
        return await instance.post(`${NODE}/${parentId}/`, elemData);
      } else {
        return await instance.post(`${NODE}/`, elemData);
      }
    } catch (err) {
    }
  },

  // ON EDIT QUERY
  async changeElement(parentId, elemData) {
    try {
      return await instance.patch(
        `${NODE}/${parentId}/${ATTRIBUTES}/`,
        elemData
      );
    } catch (err) {
    }
  },

  // ON DELETE QUERY
  async removeElement(elemId, delOpt) {
    try {
      return await instance.patch(`${NODE}/${elemId}/${HIDDEN}/`, delOpt);
    } catch (err) {
    }
  },

  // ON DRAG'N'DROP QUERY
  async pushNewTreeOrder(itemId, overItemId) {
    try {

      await instance.patch(`${NODE}/${itemId}/${ORDER}/`, {
        project_id: PROJECT_ID,
        item_type: ORGSTRUCTURE,
        item: ITEM,
        destination_node_id: Number(overItemId),
      });
    } catch (err) {
    }
  },

  // FETCH STAFF STRUCTURE
  async fetchStaff(staffData) {
    try {
      return await axios.post(EMPLOYEES_SEARCH, staffData);
    } catch (err) {
      const response = {
        status: 'error',
      };

      return response;
    }
  },

  async login(login, email, password) {
    try {
      return await axios.post(
        'https://api.cloveri.com/api/corp/login/',
        {login, email, password}
      );
    } catch(err) {
      return err.response
    }
  },
};
