import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import style from './input.module.css';

const Input = ({
  title,
  placeholder,
  type,
  onChange,
  value,
  disable,
  // active,
}) => {
  const [inputValueDirty, setInputValueDirty] = useState(true);
  const [inputValueError, setInputValueError] = useState(
    'Введите название элемента'
  );

  // ON BLUR
  const blurHandler = (e) => {
      if (e.target.value === '') {
        setInputValueError('Введите название элемента');
        setInputValueDirty(false)
      } else {
        setInputValueDirty(true)
        setInputValueError('');
      }
  };
  
  useEffect(() =>{
  },[inputValueDirty])

  return (
    <>
      <label
        className={cn([style.lable], {
          [style.disabled]: disable,
        })}>
        {title}
        <input
          className={cn([style.lableInput], {
            [style.errorBoder]: value,
          })}
          type={type}
          placeholder={placeholder}
          name={'elemName'}
          onChange={onChange}
          onBlur={(e) => blurHandler(e)}
          value={value}
          disabled={disable ? true : false}
        />
        {inputValueDirty ? null : <div className={style.EmtyInputAlert}>
          {inputValueError}
        </div>}
      </label>
    </>
  );
};

export default Input;
