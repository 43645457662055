import React, { useState, useEffect } from 'react';

import './CloseModal.css';

const CloseModal = ({ active, name, description, team_ids, setInputValue, setTextareaValue, setTeamsId, handleSubmit, setIsShowModal, closeAllPopUps, closeModalWindow}) => {

  const clickOnOverlay = () =>{
    closeModalWindow()
    setIsShowModal(true);
  }

  const handleAbord = () =>{
    setInputValue('');
    setTextareaValue('');
    setIsShowModal(false);
    closeAllPopUps();
  }

  const clickCross = () => {
    setInputValue(name);
    setTextareaValue(description);
    setTeamsId(team_ids);
    setIsShowModal(true);
    closeModalWindow();
  };


  return (
    <div className={active ? 'modalActive' : 'modal'} id='closeModalOverlay' onClick={clickOnOverlay}>
      <div className='modalContent' onClick = {(e) =>{e.stopPropagation()}}>
        {/* CROSS BUTTON */}
        <div onClick={clickCross} className='modalCross'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M24 2.1L21.9 0L12 9.9L2.1 0L0 2.1L9.9 12L0 21.9L2.1 24L12 14.1L21.9 24L24 21.9L14.1 12L24 2.1Z'
              fill='white'
            />
          </svg>
        </div>
        {/* MODAL HEADER */}
        <h2>Подтверждение</h2>
        <div className='closeModal'>
          <p>Закрыть окно? Введенные данные не будут сохранены?</p>
          <div className='closeBtns'>
            <button
              onClick={handleAbord}
              className="closeBntModal">
              Закрыть
            </button>
            <button
              onClick={handleSubmit}
              className='saveCloseBtnModal'>
              Сохранить и закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseModal;