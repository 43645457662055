import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import { PROJECT_ID, ORGSTRUCTURE, ITEM, LIMIT } from '../../../constants';
import style from './Pagination.module.css';
import { findStaff } from '../../../redux/actions/staff';

const Pagination = ({ staffList }) => {
  const dispatch = useDispatch();
  const { page, limit, total, nodeId } = useSelector(({ staff }) => staff);
  const [portionNumber, setPortionNumber] = useState(1);

  // const portionSize = 4;

  const pages = [];
  const pagesCount = Math.ceil(total / LIMIT);
  // const portionCount = Math.ceil(pagesCount / LIMIT);
  const leftEdgeNumber = (portionNumber - 1) * limit + 1;
  const rightEdgeNumber = portionNumber * limit;

  for (let i = 1; i <= pagesCount; i++) {
    pages.push(i);
  }

  const handlePage = (e) => {
    let pageCount = e.currentTarget.dataset.page;

    const staffData = {
      node_id: nodeId,
      project_id: PROJECT_ID,
      item_type: ORGSTRUCTURE,
      item: ITEM,
      page: pageCount,
      limit: LIMIT,
      is_only_node: false,
    };

    if (pageCount < 1 || pageCount > pages.length) {
      return;
    } else {
      dispatch(findStaff(staffData));
    }

    if (pageCount > rightEdgeNumber) {
      setPortionNumber(portionNumber + 1);
    } else if (pageCount < leftEdgeNumber) {
      setPortionNumber(portionNumber - 1);
    } else {
      return;
    }
  };

  return (
    <div className={style.pagination}>
      <div className={style.paginationContainer}>
        {/* ARROW LEFT */}
        {staffList <= 0 ? null : (
          <div
            onClick={staffList !== -1 ? handlePage : null}
            data-page={!page ? 0 : page - 1}
            className={cn([style.paginationArrow], {
              [style.disable]: page - 1 < 1,
            })}>
            <svg
              width='8'
              height='14'
              viewBox='0 0 8 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M7 13L1 7L7 1'
                stroke='#D9D9D9'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        )}

        <div className={style.paginationPageWrapper}>
          {leftEdgeNumber > 1 ? (
            <>
              <div
                onClick={handlePage}
                data-page={!pages ? 0 : pages[0]}
                className={cn([style.paginationPage], {
                  [style.active]: page === pages[0],
                })}>
                {pages[0]}
              </div>

              {leftEdgeNumber - 1 === pages[0] ? null : (
                <div
                  onClick={() => setPortionNumber(portionNumber - 1)}
                  className={style.paginationDots}>
                  ...
                </div>
              )}
            </>
          ) : (
            ''
          )}

          {staffList === -1 ? (
            <>
              <div className={cn([style.paginationArrow], [style.disable])}>
                <svg
                  width='8'
                  height='14'
                  viewBox='0 0 8 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M7 13L1 7L7 1'
                    stroke='#D9D9D9'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              <div className={cn([style.paginationPage], [style.active])}>
                1
              </div>
              <div className={cn([style.paginationArrow], [style.disable])}>
                <svg
                  width='8'
                  height='14'
                  viewBox='0 0 8 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M1 13L7 7L1 1'
                    stroke='#D9D9D9'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </>
          ) : (
            pages
              .filter((portion) => {
                return portion >= leftEdgeNumber && portion <= rightEdgeNumber;
              })
              .map((pageItem) => {
                return (
                  <div
                    key={pageItem}
                    onClick={handlePage}
                    data-page={!pages ? 0 : pageItem}
                    className={cn([style.paginationPage], {
                      [style.active]: page === pageItem,
                    })}>
                    {pageItem}
                  </div>
                );
              })
          )}

          {total > LIMIT && rightEdgeNumber < pages.length ? (
            <>
              {rightEdgeNumber + 1 === pages.length ? null : (
                <div
                  onClick={() => setPortionNumber(portionNumber + 1)}
                  className={style.paginationDots}>
                  ...
                </div>
              )}

              <div
                onClick={handlePage}
                data-page={!pages ? 0 : pages.length}
                className={cn([style.paginationPage], {
                  [style.active]: page === pages.length,
                })}>
                {pages.length}
              </div>
            </>
          ) : (
            ''
          )}
        </div>
        {/* ARROW RIGHT */}
        {staffList <= 0 ? null : (
          <div
            onClick={staffList !== -1 ? handlePage : null}
            data-page={!page ? 0 : page + 1}
            className={cn([style.paginationArrow], {
              [style.disable]: page + 1 > pages.length,
            })}>
            <svg
              width='8'
              height='14'
              viewBox='0 0 8 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1 13L7 7L1 1'
                stroke='#D9D9D9'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;
