import React, { useState } from 'react';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';

import style from './EditModal.module.css';
import { changeElem } from '../../../redux/actions/orgStructureTree';
import Input from '../../input/input';
import Textarea from '../../Textarea/Textarea';
import Modal from '../../common/Modal/Modal';
import TeamSelect from '../../select/TeamSelect';
import CloseModal from '../CloseModal/CloseModal';
import { PROJECT_ID, ORGSTRUCTURE, ITEM} from '../../../constants';

const EditModal = ({ closePopup, nonEdit, parentId, elem }) => {
  const dispatch = useDispatch();
  const [showCloseModal, setShowCloseModal] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false);
  const [inputValue, setInputValue] = useState(JSON.parse(elem.attributes).name);
  const [textareaValue, setTextareaValue] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [listTeamsId, setTeamsId] = useState([])

  const closeAllModals = () =>{
    setShowCloseModal(false)
    closePopup()
  }

  const disableCloseModal = () =>{
    setShowCloseModal(false)
  }

  const pressExit = () =>{
    setShowCloseModal(true)
    setIsShowModal(false)
  }

  const handleOpen = (e) => {
    setIsShowModal(true);
  };

  const onChangeInputName = (e) => {
    setInputValue(e.target.value);
    setIsDisabled(false);
  };

  const onChangeTextarea = (e) => {
    setTextareaValue(e.target.value);
    setIsDisabled(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // NEW ELEM DATA FROM INPUT AND TEXTAREA
    const newElemData = {
      project_id: PROJECT_ID,
      item_type: ORGSTRUCTURE,
      item: ITEM,
      attributes: JSON.stringify({
        name: inputValue.trim() || JSON.parse(elem.attributes).name,
        description: textareaValue.trim() || JSON.parse(elem.attributes).description,
        team_ids: listTeamsId,
      }),
    };

    // DISPATCH NEW ELEM DATA
    dispatch(changeElem(parentId, newElemData));
    
    // CLEAR FIELDS AND CLOSE MODAL
    setInputValue('');
    setTextareaValue('');
    setIsShowModal(false);
    closePopup();
    setShowCloseModal(false)
  };

  return (
    <div className={style.modal}>
      {/* EDIT MODAL BUTTON */}
      <div
        onClick={handleOpen}
        className={cn([style.popupEditManage], {
          [style.disabled]: nonEdit,
        })}>
        <div className={style.popupEditIcon}>
          <svg
            className={style.popEdit}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M10 18.1211H19'
              stroke='#D9D9D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M14.5 1.62132C14.8978 1.2235 15.4374 1 16 1C16.2786 1 16.5544 1.05487 16.8118 1.16148C17.0692 1.26808 17.303 1.42434 17.5 1.62132C17.697 1.8183 17.8532 2.05216 17.9598 2.30953C18.0665 2.5669 18.1213 2.84274 18.1213 3.12132C18.1213 3.3999 18.0665 3.67574 17.9598 3.93311C17.8532 4.19048 17.697 4.42434 17.5 4.62132L5 17.1213L1 18.1213L2 14.1213L14.5 1.62132Z'
              stroke='#D9D9D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>

        <div className={style.popupEditName}>Редактировать</div>
      </div>

      {/* MODAL */}
      {isShowModal && (
        <>
          {createPortal(
            <Modal
              clickOnOverlay={pressExit}
              header='Редактирование элемента структуры'
              open={isShowModal}
              closePopup={pressExit}>
              {/* MODAL FORM */}
              <form className={style.modalForm} onSubmit={handleSubmit}>
                <Input
                  title={'Название элемента'}
                  placeholder={'Введите текст'}
                  type={'text'}
                  value={inputValue}
                  onChange={onChangeInputName}
                  active={inputValue || JSON.parse(elem.attributes).name}
                />

                <Textarea
                  title={'Описание элемента'}
                  type={'text'}
                  value={
                    textareaValue || JSON.parse(elem.attributes).description
                  }
                  onChange={onChangeTextarea}
                />

                <TeamSelect 
                setTeamsId={setTeamsId} 
                listTeamsId={listTeamsId}
                elementId={parentId}/>

                {/* MODAL SUBMIT BUTTON */}
                {isDisabled ? <button
                  disabled
                  className={cn([style.modalBtn], {
                    [style.disable]: isDisabled,
                  })}>
                  Сохранить
                </button> : <button
                  className={cn([style.modalBtn], {
                    [style.disable]: isDisabled,
                  })}>
                  Сохранить
                </button>}
              </form>
            </Modal>,
            document.body
          )}
        </>
      )}
      {showCloseModal && (
        <>
          {createPortal(
          <CloseModal
            active={showCloseModal}
            name={inputValue || JSON.parse(elem.attributes).name}
            description={textareaValue || JSON.parse(elem.attributes).description}
            team_ids={listTeamsId}
            setInputValue={setInputValue} 
            setTextareaValue={setTextareaValue} 
            setIsShowModal={setIsShowModal} 
            setTeamsId={setTeamsId}
            handleSubmit ={handleSubmit}
            closeAllPopUps={closeAllModals}
            closeModalWindow={disableCloseModal}
          />,
          document.body
          )}
        </>
      )}
    </div>
  );
};

export default EditModal;
