import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import style from './CreateElement.module.css';
import { createElem } from '../../../redux/actions/orgStructureTree';
import { PROJECT_ID, ORGSTRUCTURE, ITEM } from '../../../constants';
import Input from '../../input/input';
import Textarea from '../../Textarea/Textarea';
import TeamSelect from '../../select/TeamSelect';
import { createPortal } from 'react-dom';
import CloseModal from '../CloseModal/CloseModal';

const CreateElement = ({ structLength }) => {
  const dispatch = useDispatch();
  const [isShowModal, setIsShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [listTeamsId, setTeamsId] = useState([]);
  const [showCloseModal, setShowCloseModal] = useState(false);

  // SHOW MODAL
  const handleShow = () => {
    setIsShowModal(true);
  };

  const clickOnOverlay = () => {
    setShowCloseModal(true);
    setIsShowModal(false);
  };

  const closeAllModals = () => {
    setShowCloseModal(false);
    setIsShowModal(false);
  };

  const disableCloseModal = () => {
    setShowCloseModal(false);
  };

  // CLOSE MODAL
  const handleClose = (e) => {
    e.stopPropagation();
    setShowCloseModal(true);
    setIsShowModal(false);;
  };

  // INPUT HANDLER
  const onChangeInputName = (e) => {
    setInputValue(e.target.value);
  };

  // TEXTAREA HENDLER
  const onChangetextarea = (e) => {
    setTextareaValue(e.target.value);
  };

  // SUBMIT HANDLER
  const handleSubmit = (e) => {
    e.preventDefault();

    if (inputValue === '') return;

    // NEW ELEM DATA FROM INPUT AND TEXTAREA
    const parentId = null;
    const newElemData = {
      project_id: PROJECT_ID,
      item_type: ORGSTRUCTURE,
      item: ITEM,
      attributes: JSON.stringify({
        name: inputValue.trim(),
        description: textareaValue.trim(),
        team_ids: listTeamsId,
      }),
    };

    // DISPATCH NEW ELEM DATA
    dispatch(createElem(parentId, newElemData));

    // CLEAR FIELDS AND CLOSE MODAL
    setInputValue('');
    setTextareaValue('');
    setIsShowModal(false);
    setTeamsId([]);
    setShowCloseModal(false);
  };

  // ADD FIX POSITION TO WINDOW WHEN MODAL SHOW
  if (isShowModal) {
    document.body.classList.add('activeModal');
  } else {
    document.body.classList.remove('activeModal');
  }

  return (
    <>
      <div onClick={handleShow} className={style.structureAddElement}>
        <div className={style.modalPlus}>
          <svg
            className={style.plusIcon}
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M8 1V15'
              stroke='#D9D9D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M1 8H15'
              stroke='#D9D9D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>

        {structLength === 0 ? (
          <div className={style.structureAddElemeText}>
            Кликните здесь, чтобы создать первый элемент
          </div>
        ) : (
          <div className={style.structureAddElemeText}>Новый элемент</div>
        )}
      </div>

      {/* MODAL */}
      {isShowModal && (
        <div className={style.modalOverlay} onClick={clickOnOverlay}>
          <div className={style.modalContent}
           onClick={(e) => {
            e.stopPropagation();
          }}>
            {/* CROSS BUTTON */}
            <div onClick={handleClose} className={style.modalCross}>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M24 2.1L21.9 0L12 9.9L2.1 0L0 2.1L9.9 12L0 21.9L2.1 24L12 14.1L21.9 24L24 21.9L14.1 12L24 2.1Z'
                  fill='white'
                />
              </svg>
            </div>

            {/* MODAL HEADER */}
            <h2>Создание элемента структуры</h2>

            {/* MODAL FORM */}
            <form className={style.modalForm} onSubmit={handleSubmit}>
              <Input
                title={'Название элемента'}
                placeholder={'Введите текст'}
                type={'text'}
                value={inputValue}
                onChange={onChangeInputName}
              />

              <Textarea
                value={textareaValue}
                customClass={'input_element_description'}
                title={'Описание элемента'}
                type={'text'}
                onChange={onChangetextarea}
              />

              <TeamSelect setTeamsId={setTeamsId} listTeamsId={listTeamsId} />

              {/* MODAL SUBMIT BUTTON */}
              <button
                className={cn([style.modalBtn], {
                  [style.disable]: inputValue === '',
                })}>
                Сохранить
              </button>
            </form>
          </div>
        </div>
      )}
      {showCloseModal && (
        <>
          {createPortal(
            <CloseModal
              active={showCloseModal}
              name={inputValue}
              description={textareaValue}
              team_ids={listTeamsId}
              setInputValue={setInputValue}
              setTextareaValue={setTextareaValue}
              setIsShowModal={setIsShowModal}
              setTeamsId={setTeamsId}
              handleSubmit={handleSubmit}
              closeAllPopUps={closeAllModals}
              closeModalWindow={disableCloseModal}
            />,
            document.body
          )}
        </>
      )}
    </>
  );
};

export default CreateElement;
