import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TEAMS, TOKEN } from '../../constants';
import '../select/TeamSelect.css';

const TeamSelect = ({ setTeamsId, listTeamsId, elementId }) => {
  const [open, setOpen] = useState(false);
  const [teams, setTeam] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [chosenTeamList, setChosenTeamList] = useState([]);
  const { structure } = useSelector(({ structure }) => structure);
  
  const token = TOKEN

  const getTeams = () => {
    axios
      .get(TEAMS,
      {
        headers:{ Authorization: `JWT ${token}`}
      }
      )
      .then((response) => {
        const result = (response.data)
        return result 
      })
      .then((result) => {
        setTeam(result.results)
        const  listTeams = (result.results) 
        let element = structure.find((item) => item.id === elementId);
        const elementAttributes = JSON.parse(element.attributes)
        return ({listTeams, elementAttributes})
      })
      .then((listTeams) =>{
        let reusltArr = []
        reusltArr = (listTeams.elementAttributes.team_ids.map((elem) => {
          setTeamsId(listTeams.elementAttributes.team_ids)
          return(listTeams.listTeams.filter((item) => item.id === elem));
        }))
        return reusltArr
      })
      .then((reusltArr) =>{
        let FinishArr = reusltArr.map(item => item[0])
        setChosenTeamList(FinishArr)
      })
  };


  useEffect(() => {
    getTeams();
  }, []);

  const filterTeams = teams.filter((team) => {
    return team.name.toLowerCase().includes(inputValue.toLowerCase());
  });

  const emptyList = filterTeams.length === 0;

  function hendlerClick(e) {
    setOpen(true);
    e.target.attributes.placeholder.value = '';
    e.target.parentElement.style.border = 'solid 1px #2E3192';
  }

  function hendlerOnChange(e) {
    setInputValue(e.target.value);
  }

  function hendlerChoseElement(e) {
    if (e.target.id) {
      let item = teams.find((item) => item.id === e.target.id);
      let teamId = e.target.id;
      if (chosenTeamList.includes(item)) {
        let itemIndex = Number(chosenTeamList.indexOf(item));
        let itemIndexInReques = Number(listTeamsId.indexOf(item));
        chosenTeamList.splice(itemIndex, 1);
        listTeamsId.splice(itemIndex, 1);
        setChosenTeamList([...chosenTeamList]);
        setTeamsId([...listTeamsId]);
        e.target.children[0].style.display = 'none';
        setInputValue('')
      } else {
        setChosenTeamList([...chosenTeamList, item]);
        e.target.children[0].style.display = 'block';
        e.target.style.background = '#F7F7FB'
        setTeamsId([...listTeamsId, teamId]);
        setInputValue('')
      }
    } else {
    }
  }

  function hendlerAddNewTeam() {
    if(inputValue !== ''){setChosenTeamList([
      ...chosenTeamList,
      {
        name: inputValue,
      },
    ]);}else{}
  }

  function hendlerCloseSelect(e) {
    setOpen(!open);
    e.target.parentElement.style.border = 'solid 1px #D9D9D9';
  }

  return (
    <div className='TeamSelect_div'>
      <p>Команды</p>
      <div className='TeamSelect_divInput'>
        {chosenTeamList ? (
          <div className='chosenTeams_div'>
            {chosenTeamList.map((item) => (
              <span key={item.id} id={item.id} className='chosenTeam'>
                {item.name},
              </span>
            ))}
          </div>
        ) : null}
        {chosenTeamList.length !== 0 ? <input
          placeholder=''
          value={inputValue}
          className='TeamSelect_input'
          onChange={hendlerOnChange}
          onClick={hendlerClick}></input>
          : 
          <input
          value={inputValue}
          placeholder='Выберите из списка'
          className='TeamSelect_input'
          onChange={hendlerOnChange}
          onClick={hendlerClick}></input>} 
        <input
          type={'button'}
          className='inputArrowButton'
          onClick={hendlerCloseSelect}
          id={'inputButtonArrowID'}></input>
        <label htmlFor='inputButtonArrowID'>
          {open ? (
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M6 9L12 15L18 9'
                stroke='#2E3192'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          ) : (
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M6 9L12 15L18 9'
                stroke='#D9D9D9'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          )}
        </label>
      </div>
      {open && (
        <div>
          <ul className='TeamSelect_list'>
            {emptyList ? (
              <li>Ничего не найдено</li>
            ) : (
              filterTeams.map((item) => (
                <li key={item.id} id={item.id} onClick={hendlerChoseElement} className='TeamSelectItem'>
                  {item.name}
                  <svg
                    className='checkedTeamSvg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M20 6L9 17L4 12'
                      stroke='#2E3192'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </li>
              ))
            )}
          </ul>
          <div className='TeamSelect_button-wrapper'>
           {/* <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12 5V19'
                stroke='#2E3192'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M5 12H19'
                stroke='#2E3192'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
             <input
              type='button'
              value='Новая команда'
              className='TeamSelect_button'
              onClick={hendlerAddNewTeam}
            /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamSelect;
