import React, {useEffect} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

import './App.css';
import SideMenu from './components/menu/SideMenu/SideMenu';
import Header from './components/Header/Header';
import OrgStructure from './components/OrgStructure/OrgStructure';
import Alert from './components/common/Alert/Alert';
import Logining from './components/Logining/Logining';

function App() {
  const cookies = new Cookies()
  const { alertIsActive } = useSelector(({ structure }) => structure);

  let client_token=cookies.get('cl_t')
    if(typeof client_token === 'undefined'){
      return <Logining />
    }else{
      return (    
        <div className='container'>
          {/* ALERT BOX */}
          {alertIsActive && <Alert />}
    
          {document.location.pathname !== '/login' ? <SideMenu /> : null}
          <div className='Main'>
            <Header />
            <div className='container_main'>
              <Routes>
                <Route path='/login' element={<Logining/>} />
                <Route path='structure' element={<OrgStructure />} />
              </Routes>
            </div>
          </div>
        </div>
      );
    }  
}

export default App;
