import React, { useEffect } from 'react';

import style from './Modal.module.css';

const Modal = ({ header, open, children, closePopup, clickOnOverlay}) => {
  const handleCloseAll = () => {
    closePopup();
  };

  useEffect(() => {
    document.body.classList.add('activeModal');

    return () => {
      document.body.classList.remove('activeModal');
    }
  }, []);

  if (!open) return null;

  return (
    <div className={style.modalOverlay} id='modalOverlay' onClick={clickOnOverlay}>
      <div className={style.modalContent} onClick={(e) =>{e.stopPropagation()}}>
        {/* CROSS BUTTON */}
        <div onClick={handleCloseAll} className={style.modalCross}>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M24 2.1L21.9 0L12 9.9L2.1 0L0 2.1L9.9 12L0 21.9L2.1 24L12 14.1L21.9 24L24 21.9L14.1 12L24 2.1Z'
              fill='white'
            />
          </svg>
        </div>

        {/* MODAL HEADER */}
        <h2>{header}</h2>
        {children}
      </div>
    </div>
  );
};

export default Modal;
