import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

import {
  fetchTree,
  setNewTreeOrder,
} from '../../redux/actions/orgStructureTree';
import CreateElement from '../popup/CreateElement/CreateElement';
import style from './OrgStructure.module.css';
import OrgStructureTreeElem from '../OrgStructureTreeElem/OrgStructureTreeElem';
import StaffStructure from '../StaffStructure/StaffStructure';
import Logining from '../Logining/Logining';

const OrgStructure = ({elem}) => {
  const cookies = new Cookies()
  const dispatch = useDispatch();
  const { structure } = useSelector(({ structure }) => structure);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const [itemId, setItemId] = useState(0)
  let client_token=cookies.get('cl_t')
  

  // DRAG END
  const handleDragEnd = () => {
    // duplicate elem
    let items = [...structure];

    // remove and save the dragged item content
    const draggedItemContent = items.splice(dragItem.current, 1)[0];

    // switch the position
    items.splice(dragOverItem.current, 0, draggedItemContent);

    // update the actual array
    dispatch(setNewTreeOrder(dragItem.current, dragOverItem.current));

    // reset the position
    dragItem.current = null;
    dragOverItem.current = null;
  };

  useEffect(() => {
    dispatch(fetchTree());
    window.scrollTo(0, 0);
  }, []);

  
    if(typeof client_token === 'undefined'){
      return <Logining />
    }else{
      return (
        <section className={style.structure}>
          <h1>Организационная структура</h1>
          <div className={style.structureWrapper}>
            {/* STRUCTURE TREE */}
            <div className={style.structureContainer}>
              {structure &&
                structure.map((elem) => {
                  // TODO: сделать скрытие/раскрытие по шеврону
                  return (
                    <OrgStructureTreeElem
                      setItemId={setItemId}
                      key={elem.id}
                      elem={elem}
                      handleDragEnd={handleDragEnd}
                      dragItem={dragItem}
                      dragOverItem={dragOverItem}
                    />
                  );
                })}

              {/* CREATE NEW ELEMENT */}
              <CreateElement structLength={structure.length} />
            </div>

            {/* RIGHT SIDE STAFF */}
            <StaffStructure itemId={itemId}/>
          </div>
        </section>
      );
    }
};

export default OrgStructure;
