import React from 'react';

import style from './Header.module.css';
import user from '../../assets/imgs/UserImage.png';

const Header = () => {
  return (
    <div className='Header'>
      <button className={style.headerButton}>
        {/* <img src={user} alt='User image' /> */}
      </button>
    </div>
  );
};

export default Header;
