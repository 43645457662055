import { appAPI } from '../../api';
import { SET_STAFF, SET_NODE_ID, SET_ACTIVE_STAFF_BTN } from '../../constants';

const setStaff = (payload) => ({ type: SET_STAFF, payload });
const setNodeId = (payload) => ({ type: SET_NODE_ID, payload });
export const setActiveStaffBtn = (payload) => ({ type: SET_ACTIVE_STAFF_BTN, payload});

// FETCH STAFF LIST
export const findStaff = (staffData) => async (dispatch) => {
  try {
    const response = await appAPI.fetchStaff(staffData);
    if (response.status === 'error') {
      dispatch(setStaff(-1));
    } else {
      dispatch(setNodeId(staffData.node_id));
      dispatch(setStaff(response.data));
    }
  } catch (err) {
  }
};


