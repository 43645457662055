import { combineReducers } from 'redux';

import menu from './menu';
import structure from './structure';
import staff from './staff';
import login from './login';

const rootReducer = combineReducers({
  menu,
  structure,
  staff,
  login,
});

export default rootReducer;
