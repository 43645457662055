import { appAPI } from '../../api';
import { SET_STRUCTURE, SET_ALERT_IS_ACTIVE } from '../../constants';

const setOrgTree = (payload) => ({ type: SET_STRUCTURE, payload });
export const setIsAlert = (payload) => ({ type: SET_ALERT_IS_ACTIVE, payload });

export const fetchTree = () => async (dispatch) => {
  try {
    const response = await appAPI.getTree();
    dispatch(setOrgTree(response.data));
  } catch (err) {
  }
};

export const createElem = (parentId, elemData) => async (dispatch) => {
  try {
    // POST ADD NEW ELEM
    const newElem = await appAPI.addElement(parentId, elemData);

    // CHOOSE NEW ELEM ALERT
    if (newElem.statusText === 'Created') {
      dispatch(
        setIsAlert({
          alertMsg: 'Элемент успешно создан',
          alert: true,
          alertKinde: 0,
        })
      );
    } else {
      dispatch(
        setIsAlert({
          alertMsg: 'Не удалось создать элемент',
          alert: true,
          alertKinde: 1,
        })
      );
    }

    // GET TREE
    const response = await appAPI.getTree();
    dispatch(setOrgTree(response.data));
  } catch (err) {
  }
};

export const changeElem = (parentId, elemData) => async (dispatch) => {
  try {
    // POST CHNAGE ELEM
    const postData = await appAPI.changeElement(parentId, elemData);

    // CHOOSE CHANGE ELEM ALERT
    // TO DO: Узнать какие еще есть статусы
    if (postData.statusText === 'Created') {
      dispatch(
        setIsAlert({
          alertMsg: 'Элемент успешно изменен',
          alert: true,
          alertKinde: 0,
        })
      );
    } else {
      dispatch(
        setIsAlert({
          alertMsg: 'Не удалось внести изменения',
          alert: true,
          alertKinde: 1,
        })
      );
    }

    // GET TREE
    const response = await appAPI.getTree();
    dispatch(setOrgTree(response.data));
  } catch (err) {
  }
};

export const removeElem = (elemId, delOpt) => async (dispatch) => {
  try {
    // POST REMOVE ELEM
    const removeElem = await appAPI.removeElement(elemId, delOpt);

    // CHOOSE REMOVE ELEM ALERT
    if (removeElem.statusText === 'OK') {
      dispatch(
        setIsAlert({
          alertMsg: 'Элемент успешно удален',
          alert: true,
          alertKinde: 0,
        })
      );
    } else {
      dispatch(
        setIsAlert({
          alertMsg: 'Не удалось удалить элемент',
          alert: true,
          alertKinde: 1,
        })
      );
    }

    // GET TREE
    const response = await appAPI.getTree();
    dispatch(setOrgTree(response.data));
  } catch (err) {
  }
};

export const setNewTreeOrder = (itemId, overItemId) => async (dispatch) => {
  try {
    // POST NEW TREE ORDER
    await appAPI.pushNewTreeOrder(itemId, overItemId);

    // GET TREE
    const response = await appAPI.getTree();
    dispatch(setOrgTree(response.data));
  } catch (err) {
  }
};
