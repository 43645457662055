import { SET_STRUCTURE, SET_ALERT_IS_ACTIVE } from '../../constants';

const initialState = {
  structure: [],
  alertMsg: '',
  alertIsActive: false,
  kindeOfAlert: 0,
};

const structure = (state = initialState, action) => {
  switch (action.type) {
    case SET_STRUCTURE:
      return {
        ...state,
        structure: action.payload,
      };

    case SET_ALERT_IS_ACTIVE:
      return {
        ...state,
        alertMsg: action.payload.alertMsg,
        alertIsActive: action.payload.alert,
        kindeOfAlert: action.payload.alertKinde,
      };

    default:
      return state;
  }
};

export default structure;
