import { SET_STAFF, SET_NODE_ID, SET_ACTIVE_STAFF_BTN } from '../../constants';

const initialState = {
  staffList: [],
  page: 1,
  limit: null,
  total: null,
  nodeId: null,
  isActiveStaff: null,
  team_ids:[],
};

const staff = (state = initialState, action) => {
  switch (action.type) {
    case SET_STAFF:
      return {
        ...state,
        staffList: action.payload.response || action.payload,
        page: action.payload.page,
        limit: action.payload.limit,
        total: action.payload.total,
        team_ids:action.payload.team_ids,
      };

    case SET_NODE_ID:
      return {
        ...state,
        nodeId: action.payload,
      };

    case SET_ACTIVE_STAFF_BTN:
      return {
        ...state,
        isActiveStaff: action.payload,
      };

    default:
      return state;
  }
};

export default staff;
