import Cookies from 'universal-cookie';
const cookies = new Cookies();

cookies.get('cl_t')

export const TOKEN = cookies.get('cl_t')

// export const TOKEN = localStorage.getItem('token')

// SET ACTIVE MENU
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';

// ALERTS
export const SET_ALERT_IS_ACTIVE = 'SET_ALERT_IS_ACTIVE';

// ORG STRUCTURE
export const SET_STRUCTURE = 'SET_STRUCTURE';
export const PROJECT_ID = localStorage.getItem('company_id');
export const ORGSTRUCTURE = 'orgstructure';
export const ITEM = 'start_project';

// SET SET_STAFF
export const SET_STAFF = 'SET_STAFF';
export const SET_NODE_ID = 'SET_NODE_ID';
export const SET_ACTIVE_STAFF_BTN = 'SET_ACTIVE_STAFF_BTN';
export const LIMIT = 5;

// URL`S
export const BASE_URL = 'https://tree.ms.corp.cloveri.com/api/v1/';
export const EMPLOYEES_SEARCH =
  'https://nes.ms.corp.cloveri.com/api/node_employees_search/';
export const GET_QUERY =
  `nodes/?project_id=${PROJECT_ID}&item_type=${ORGSTRUCTURE}&item=${ITEM}`;
export const NODE = 'node';
export const ATTRIBUTES = 'attributes';
export const HIDDEN = 'hidden';
export const ORDER = 'order';
export const TEAMS = 'https://api.cloveri.com/api/corp/teams'

export const SET_LOGIN = "SET_LOGIN"
export const FAIL_LOGIN = 'FAIL_LOGIN'
