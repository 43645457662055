import cn from 'classnames';

import style from './Textarea.module.css';

const Textarea = ({
  title,
  placeholder,
  type,
  onChange,
  value,
  disable,
  error,
}) => {
  return (
    <>
      <label
        className={cn([style.lable], {
          [style.disabled]: disable,
        })}>
        {title}
        <textarea
          className={cn([style.lableInput], {
            [style.errorBorder]: error,
          })}
          type={type}
          placeholder={placeholder ? placeholder : 'Введите текст'}
          onChange={onChange}
          value={value}
          disabled={disable ? true : false}
          rows='5'></textarea>
      </label>
      <div
        className={cn([style.nonError], {
          [style.lableError]: error,
        })}>
        Текст ошибки поля
      </div>
    </>
  );
};

export default Textarea;
