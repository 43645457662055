import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import style from './SideMenu.module.css';
import { setActiveMenu } from '../../../redux/actions/menu';

const SideMenu = () => {

  const dispatch = useDispatch();
  const { menu, activeMenu } = useSelector(({ menu }) => menu);
  
  const [ menuActive, setMenuActive ] = useState(false);

  const handleMenuActive = (index) => {
    dispatch(setActiveMenu(index));
  };

  const isMenuActive = () => {
    menuActive ? setMenuActive(false) : setMenuActive(true)
  }

  return (
    <nav className={cn([style.navigation], {
      [style.menuActive]: menuActive
    })}>
      <div className={style.navigationWrapper}>
        <ul className={style.navigationUl}>
          <li>
            {/* *** LOGO ************** */}
            <div className={style.navigationElem}>
              <div className={style.navigationLogoIcon}>
                <svg
                  className={style.navigationLogo}
                  width='60'
                  height='60'
                  viewBox='0 0 60 60'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M46 13.0848L29.3458 10.0009L19 28.0009L46 13.0848Z'
                    fill='#FF8E25'
                  />
                  <path
                    d='M19 13.0009V28.0009L12 20.3681L19 13.0009Z'
                    fill='#F0D031'
                  />
                  <path
                    d='M9 38.5334L19 28.0009V49.0009L9 38.5334Z'
                    fill='#31BE00'
                  />
                  <path
                    d='M32.0706 50.0009L19 28.0009L52 46.4333L32.0706 50.0009Z'
                    fill='#2E3192'
                  />
                </svg>
              </div>
              <span className={style.navigationLogoTitle}>Кловери</span>
            </div>
          </li>

          {/* *** MENU ITEMS ************** */}
          {menu &&
            menu.map((item, index) => {
              return (
                <li key={`${item}${index}`} onClick={() => handleMenuActive(index)}>
                  <Link to={item.type} className={style.navigationLink}>
                    <div
                      className={cn([style.navigationElem], {
                        [style.active]: activeMenu === index,
                      })}>
                      <div className={style.navigationIcon}>{item.icon}</div>
                      <div className={style.navigationTitle}>{item.name}</div>
                    </div>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </nav>
  );
};

export default SideMenu;
