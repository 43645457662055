import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import style from './DeleteModal.module.css';
import { PROJECT_ID, ORGSTRUCTURE, ITEM } from '../../../constants';
import Modal from '../../common/Modal/Modal';
import { removeElem } from '../../../redux/actions/orgStructureTree';

const DeleteModal = ({ nonRemove, closePopup, projectName, elemId }) => {
  const dispatch = useDispatch();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const handleOpen = (e) => {
    setIsShowModal(true);
  };

  const handleAbord = () => {
    setIsShowModal(false);
    closePopup();
  };

  const handleDelete = () => {
    // DELETE OPTIONS
    const deleteOptions = {
      project_id: PROJECT_ID,
      item_type: ORGSTRUCTURE,
      item: ITEM,
      hidden: true,
    };

    dispatch(removeElem(elemId, deleteOptions));

    setIsShowModal(false);
    closePopup();
  };

  return (
    <div className={style.modal}>
      {/* DELETE MODAL BUTTON */}
      <div
        onClick={handleOpen}
        className={cn([style.popupEditManage], {
          [style.disabled]: nonRemove,
        })}>
        <div className={style.popupEditIcon}>
          <svg
            className={style.popEdit}
            width='20'
            height='22'
            viewBox='0 0 20 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M1 5H3H19'
              stroke='#D9D9D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M17 5V19C17 19.5304 16.7893 20.0391 16.4142 20.4142C16.0391 20.7893 15.5304 21 15 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5M6 5V3C6 2.46957 6.21071 1.96086 6.58579 1.58579C6.96086 1.21071 7.46957 1 8 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V5'
              stroke='#D9D9D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>

        <div className={style.popupEditName}>Удалить</div>
      </div>

      {/* DELETE MODAL */}
      {isShowModal && (
        <>
          {createPortal(
            <Modal
              header='Удаление элемента'
              open={isShowModal}
              closePopup={closePopup}>
              <div className={style.delete}>
                <p>Вы действительно хотите удалить элемент “{projectName}”?</p>

                <div className={style.deleteBtns}>
                  <button
                    onClick={handleAbord}
                    className={style.deleteAbord}
                    disabled={isDisable}>
                    Отмена
                  </button>
                  <button
                    onClick={handleDelete}
                    className={style.deleteRemove}
                    disabled={isDisable}>
                    Удалить
                  </button>
                </div>
              </div>
            </Modal>,
            document.body
          )}
        </>
      )}
    </div>
  );
};

export default DeleteModal;
