import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import style from './StaffStructure.module.css';
import Checkbox from '../common/Checkboxes/CommonCheckbox/Checkbox';
import SwitchCheckboxes from '../common/Checkboxes/SwitchCheckboxes/SwitchCheckboxes';
import pPlug from '../../assets/icons/personPlug.svg';
import MoreButton from '../common/MoreButton/MoreButton';
import Pagination from '../common/Pagination/Pagination';
import { TEAMS, TOKEN } from '../../constants';
import axios from 'axios';

const StaffStructure = ({itemId}) => {
  const token = TOKEN

  const { staffList, isCheckbox, limit, total, team_ids} = useSelector(({ staff }) => staff);
  const [isChecked, setIsChecked] = useState(false);
  const structure = useSelector(state => state.structure.structure)
  const [elementDescription, setElementDescription] = useState('')
  const [descriptionActive, setDescriptionActive] = useState(false)
  const [elementTeams, setElementTeams] = useState([])
  const [searchIsActive,setSearchIsActive] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  
  let filterTeams = []
  if (staffList.length === 0){
  }else if(staffList.length !== 0 && staffList !== -1){
    filterTeams = staffList.filter((staff) => {
      return (staff.last_name.toLowerCase().includes(searchValue.toLowerCase()) || staff.profession_list.toLowerCase().includes(searchValue.toLowerCase()) || staff.first_name.toLowerCase().includes(searchValue.toLowerCase()))
    });
  }
  
  const hendlerSearchClick = () => {
    setSearchIsActive(!searchIsActive)
    setSearchValue('')
  }
  

  const getTeams = () => {
    axios
      .get(TEAMS,
      {
        headers:{ Authorization: `JWT ${token}`}
      }
      )
      .then((response) => {
        const result = (response.data)
        return result 
      })
      .then((result) => {
        const  listTeams = (result.results) 
        let element = structure.find((item) => item.id === itemId);
        const elementAttributes = JSON.parse(element.attributes)
        setElementDescription(elementAttributes.description)
        setDescriptionActive(!descriptionActive)
        return (listTeams)
      })
      .then((listTeams) =>{
        let reusltArr = []
        if( typeof team_ids !== 'undefined'){
          reusltArr = (team_ids.map((elem) => {
            return(listTeams.filter((item) => item.id === elem));
          }))
        }else{}        
        return reusltArr
      })
      .then((reusltArr) =>{
        let FinishArr = reusltArr.map(item => item[0].name)
        setElementTeams(FinishArr)
      })
  };  

  if (descriptionActive ) {
    document.body.addEventListener('click', () =>{
      setDescriptionActive(false)
    });
  };

  return (
    <>
      {/* STAFF */}
      <div className={style.staff} >
        {/* STAFF CONTROLL MENU */}
        <div
          className={cn([style.staffMenu], {
            [style.infoOn]: !staffList,
          })}>
          {staffList && staffList.length !== 0 ? (
            <>
              {/* INFO ICON */}
              <div className={style.staffInfoIcon} onClick={getTeams}>
              {descriptionActive ? 
              <div className={style.staffElementDescription}>
                {elementDescription.length === 0 ? 
                <p className={style.staffElementDescriptionTitle}>Описание отсутствует</p> 
                : 
                <p className={style.staffElementDescriptionTitle}>{elementDescription}</p>}
                {elementTeams.length === 0 ? 
                <p className={style.staffElementDescriptionTeams}><b style={{marginRight:'10px'}}>Команды:</b> - </p> 
                : 
                <p className={style.staffElementDescriptionTeams} onClick={(e) => console.log(e)}><b style={{marginRight:'10px'}} >Команды:</b>{`${elementTeams.map(item => item)}`}</p>}
                </div>
               : null}
                <svg
                  width='26'
                  height='26'
                  viewBox='0 0 26 26'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M12.6667 24.3333C19.11 24.3333 24.3333 19.11 24.3333 12.6667C24.3333 6.22334 19.11 1 12.6667 1C6.22334 1 1 6.22334 1 12.6667C1 19.11 6.22334 24.3333 12.6667 24.3333Z'
                    stroke='#737373'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12.666 17.3337V12.667'
                    stroke='#737373'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12.666 8H12.6777'
                    stroke='#737373'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </>
          ) : (
            ''
          )}

          <div className={style.staffSearch}>
            {/* SEARCH ICON */}
            <div className={style.search} >
              <svg onClick={hendlerSearchClick}
                width='23'
                height='23'
                viewBox='0 0 23 23'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M10.3333 19.6667C15.488 19.6667 19.6667 15.488 19.6667 10.3333C19.6667 5.17868 15.488 1 10.3333 1C5.17868 1 1 5.17868 1 10.3333C1 15.488 5.17868 19.6667 10.3333 19.6667Z'
                  stroke='#737373'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22.0008 21.9998L16.9258 16.9248'
                  stroke='#737373'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              {searchIsActive? <input value={searchValue} onChange={(e) => setSearchValue(e.target.value)} className={style.searchInput} type='text' placeholder='Поиск...'></input> : null}
            </div>

            {/* DOTS ICON */}
            {/* <div className={style.dots}>
              <svg
                width='21'
                height='5'
                viewBox='0 0 21 5'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M9.16593 2.16667C9.16593 2.811 9.68827 3.33333 10.3326 3.33333C10.9769 3.33333 11.4993 2.811 11.4993 2.16667C11.4993 1.52233 10.9769 1 10.3326 1C9.68827 1 9.16593 1.52233 9.16593 2.16667Z'
                  stroke='#737373'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M17.3339 2.16667C17.3339 2.811 17.8562 3.33333 18.5006 3.33333C19.1449 3.33333 19.6672 2.811 19.6672 2.16667C19.6672 1.52233 19.1449 1 18.5006 1C17.8562 1 17.3339 1.52233 17.3339 2.16667Z'
                  stroke='#737373'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M0.999918 2.16667C0.999918 2.811 1.52225 3.33333 2.16659 3.33333C2.81092 3.33333 3.33325 2.811 3.33325 2.16667C3.33325 1.52233 2.81092 1 2.16659 1C1.52225 1 0.999918 1.52233 0.999918 2.16667Z'
                  stroke='#737373'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div> */}
          </div>
        </div>

        {/* STAFF HEADER */}
        <div className={style.staffHeader}>
          {/* CHECKBOX */}
          {/* <div className={style.staffCheck}>
            <SwitchCheckboxes isChecked={isChecked} onChanged={setIsChecked} />
          </div> */}

          <div className={style.staffSurnameWrapper}>
            {/* SURNAME */}
            <div className={style.staffSurname}>Фамилия Имя</div>

            {/* PROFESSION */}
            <div className={style.staffProffesion}>Профессия</div>
          </div>
        </div>

        {/* STAFF CONTENT */}
        <div className={style.staffContent}>
          <div className={style.staffWrapper}>
            {staffList !== -1 && staffList.length === 0 ? (
              <>
              {structure.length === 0 ? // EMPTY STAFF
              <div className={style.empty}>
                <p>
                  Добавьте первый элемент в структуру. 
                  Список прикрепленных к нему сотрудников появится здесь.
                </p>                  
              </div> 
              : 
              <div className={style.empty}>
                <p>
                  Кликните 
                  <span>
                    <svg
                      width='100'
                      height='32'
                      viewBox='0 0 105 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <rect width='105' height='32' rx='4' fill='white' />
                      <path
                        d='M25 25V23C25 21.9391 24.5786 20.9217 23.8284 20.1716C23.0783 19.4214 22.0609 19 21 19H13C11.9391 19 10.9217 19.4214 10.1716 20.1716C9.42143 20.9217 9 21.9391 9 23V25'
                        stroke='#D9D9D9'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M17 15C19.2091 15 21 13.2091 21 11C21 8.79086 19.2091 7 17 7C14.7909 7 13 8.79086 13 11C13 13.2091 14.7909 15 17 15Z'
                        stroke='#D9D9D9'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M31 24.9999V22.9999C30.9993 22.1136 30.7044 21.2527 30.1614 20.5522C29.6184 19.8517 28.8581 19.3515 28 19.1299'
                        stroke='#D9D9D9'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M24 7.12988C24.8604 7.35018 25.623 7.85058 26.1676 8.55219C26.7122 9.2538 27.0078 10.1167 27.0078 11.0049C27.0078 11.8931 26.7122 12.756 26.1676 13.4576C25.623 14.1592 24.8604 14.6596 24 14.8799'
                        stroke='#D9D9D9'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                 в элементе структуры, чтобы увидеть список сотрудников
                </p>
              </div>
              }
              </>            
            ) : (
              // FILL STAFF
              <>
                {staffList === -1 ? (
                  <div className={style.staffWrapper}>
                    <div className={style.empty}>
                      <p>Сотрудников не найдено</p>
                    </div>
                  </div>
                ) : staffList.length === 0 ? (
                  ''
                ) : (
                  filterTeams.map((item, index) => (
                    <div key={item.email + index} className={style.staffItem}>
                      {/* CHECKBOX */}
                      {/* <div className={style.fancyCheck}>
                        <Checkbox isChecked={isChecked} />
                      </div> */}

                      <div className={style.staffInfoWrapper}>
                        {/* PERSON INFO */}
                        <div className={style.staffInfo}>
                          <div
                            className={cn([style.staffInfoPersonIcon], {
                              [style.intern]: item.grade_list === 'Стажер',
                              [style.jun]:
                                item.grade_list=== 'Младший специалист',
                              [style.mid]: item.grade_list === 'Специалист',
                              [style.senior]:
                                item.grade_list === 'Старший специалист',
                              [style.lid]: item.grade_list=== 'Эксперт',
                            })}>
                            {/* <img src={pPlug} alt={item.first_name} /> */}
                            <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="17" cy="17" r="15.5" fill="#2E3192" stroke="#2E3192" strokeWidth="3"/>
                              <path d="M28 34V30.3333C28 28.3884 27.4205 26.5232 26.3891 25.1479C25.3576 23.7726 23.9587 23 22.5 23H11.5C10.0413 23 8.64236 23.7726 7.61091 25.1479C6.57946 26.5232 6 28.3884 6 30.3333V34" fill="white"/>
                              <path d="M28 34V30.3333C28 28.3884 27.4205 26.5232 26.3891 25.1479C25.3576 23.7726 23.9587 23 22.5 23H11.5C10.0413 23 8.64236 23.7726 7.61091 25.1479C6.57946 26.5232 6 28.3884 6 30.3333V34" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M16.9993 18.6667C19.9449 18.6667 22.3327 16.2789 22.3327 13.3333C22.3327 10.3878 19.9449 8 16.9993 8C14.0538 8 11.666 10.3878 11.666 13.3333C11.666 16.2789 14.0538 18.6667 16.9993 18.6667Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                          </div>

                          <div>{`${item.last_name} ${item.first_name}`}</div>
                        </div>

                        {/* PROFESSION */}
                        <div className={style.staffProff}>
                            <React.Fragment
                              key={
                                item.email + item.profession_list
                              }>{`${item.profession_list} `}</React.Fragment>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </>
            )}
          </div>
        </div>
        {total <= limit ? null : <MoreButton staffList={staffList} />}        
        <Pagination staffList={staffList} />
      </div>
    </>
  );
};

export default StaffStructure;
