import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import style from './PopupEdit.module.css';
import EditModal from '../EditModal/EditModal';
import DeleteModal from '../DeleteModal/DeleteModal';

const PopupEdit = ({ nonEdit, nonRemove, parentId, elem }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const popupRef = useRef();

  const isPopupVisible = () => {
    setShowPopup(!showPopup);
    setIsPopupActive(!isPopupActive);
  };

  const handleOutsideClick = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());

    if (!path.includes(popupRef.current)) {
      setShowPopup(false);
      setIsPopupActive(false);
    }
  };

  useEffect(() => {
    document
      .getElementById('root')
      .addEventListener('click', handleOutsideClick);
  }, []);

  return (
    <>
      <div ref={popupRef}>
        <div className={style.popup} onClick={isPopupVisible}>
          <svg
            className={cn([style.popSpread], {
              [style.popHighlight]: isPopupActive,
            })}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z'
              stroke='#D9D9D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z'
              stroke='#D9D9D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z'
              stroke='#D9D9D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>

        {showPopup && (
          <div className={style.popupEdit}>
            {/* EDIT BUTTON */}

            <EditModal
              closePopup={isPopupVisible}
              parentId={parentId}
              elem={elem}
            />

            {/* DELETE BUTTON */}
            <DeleteModal
              nonRemove={nonRemove}
              closePopup={isPopupVisible}
              projectName={JSON.parse(elem.attributes).name}
              elemId={parentId}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PopupEdit;
